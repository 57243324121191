import { Injectable, Injector } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { IResetPassword } from '../models/auth';
import { IUserAccount } from '../models/user';
import { AuthService } from './auth.service';
import { BaseCrudService } from './base-crud.service';

interface IVerifyCode {
  verification_code: string;
  verification_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseCrudService<IUserAccount> {
  constructor(
    injector: Injector,
    private authService: AuthService
  ) {
    super(injector);
    this.path = '/client/account';
  }

  getOne(): Observable<IUserAccount> {
    return this.requestService.getJSON(this.path + '/info', {});
  }

  updateUser(data: IUserAccount): Observable<unknown> {
    return this.requestService.patchFile(`${this.path}/info`, { data }).pipe(
      map(res => {
        this.authService.authChange$.next(true);
        return res;
      })
    );
  }

  getVerifyCode(data: { phone_number: string }): Observable<IVerifyCode> {
    return this.requestService.getJSON(this.path + '/verification-code', { data });
  }

  verifyCode(data: IVerifyCode): Observable<{account_token: string}> {
    return this.requestService.postJSON(this.path + '/verification-code', { data });
  }

  changePassword(data: IResetPassword): Observable<IResetPassword> {
    return this.requestService.postJSON<IResetPassword>(`${this.path}/change-password`, { data });
  }

  /**
   * @deprecated The method should not be used
   */
  override delete(_id: string): Observable<IUserAccount> {
    return throwError(() => 'Not Found');
  }
}
